<template>
  <div class="register">
    <header>
      <cc-logo-header type="register"/>
    </header>
    <div class="content">
      <div class="form-wrapper">
        <div class="title">欢迎注册CloudDM</div>
        <div class="form">
          <a-form-model :ref="registerFormRef" :label-col="labelCol" :model="registerForm" :rules="registerRuleValidate"
                        :wrapper-col="wrapperCol">
            <a-form-model-item v-if="errorMsg">
              <a-alert show-icon type="error">{{ errorMsg }}</a-alert>
            </a-form-model-item>
            <a-form-model-item v-for="(item,i) in Object.keys(registerForm)" :key="item"
                               :label="registerFormLabel[item]"
                               :prop="item">
              <a-popover trigger="click" v-if="item === 'password'" placement="right">
                <template slot="content">
                  <p class="pass-valid">
                    <cc-iconfont :name="passLengthValid?'valid-success':'valid-error'" size="12"
                                 :color="passLengthValid ? 'green' : 'red'" style="margin-right: 2px;"/>
                    长度为8-32个字符
                  </p>
                  <p class="pass-valid">
                    <cc-iconfont :name="passPhoneValid?'valid-success':'valid-error'" size="12"
                                 :color="passPhoneValid ? 'green' : 'red'" style="margin-right: 2px;"/>
                    密码不能包含手机号
                  </p>
                  <p class="pass-valid">
                    <cc-iconfont :name="passCharNumValid?'valid-success':'valid-error'" size="12"
                                 :color="passCharNumValid ? 'green' : 'red'" style="margin-right: 2px;"/>
                    必须包含英文和数字
                  </p>
                </template>
                <a-input v-model="registerForm[item]" :placeholder="`请输入${registerFormLabel[item]}`"
                         :style="{width: `${item === 'verifyCode' ? 200 : 330}px`}"
                         type="password"
                         autocomplete="new-password" size="large" @pressEnter="handleEnter(i)"/>
              </a-popover>
              <a-input v-model="registerForm[item]" :placeholder="`请输入${registerFormLabel[item]}`" v-else
                       :style="{width: `${item === 'verifyCode' ? 200 : 330}px`}"
                       :type="item === 'password' || item === 'rePassword' ? 'password' : 'text'"
                       autocomplete="new-password" size="large" @pressEnter="handleEnter(i)"/>
              <cc-sms-button v-if="item === 'verifyCode'" :phoneNumber="registerForm.phone"
                             :verifyCodeType="VERIFY_CODE_TYPE.REGISTER"
                             :verifyType="VERIFY_TYPE.SMS"
                             class="register-sms-button"
                             size="large"
                             style="margin-left: 10px;width: 120px;"/>
            </a-form-model-item>
            <a-form-model-item label=" ">
              <a-button class="register-btn" size="large" style="width: 330px"
                        type="primary"
                        @click.native="handleSubmit">注册
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <footer>
      <cc-footer/>
    </footer>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import CCLogoHeader from '@components/CCLogoHeader';
import CCFooter from '@components/CCFooter';
import { VERIFY_CODE_TYPE, VERIFY_TYPE } from '@/consts';

export default {
  name: 'Register',
  components: {
    CcFooter: CCFooter,
    CcLogoHeader: CCLogoHeader
  },
  data() {
    const validateConfirmPassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入你的密码'));
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次密码不一致'));
      } else {
        callback();
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      const reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
      if (value === '') {
        callback(new Error('请再次输入你的密码'));
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次密码不一致'));
      } else if (this.registerForm.phone && value.includes(this.registerForm.phone)) {
        callback(new Error('密码不能包含手机号'));
      } else if (!reg.test(value)) {
        callback(new Error('必须包含英文和数字'));
      } else {
        callback();
      }
    };
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      VERIFY_CODE_TYPE,
      VERIFY_TYPE,
      registerFormRef: 'registerFormRef',
      registerLoading: false,
      timer: null,
      errorMsg: '',
      registerForm: {
        phone: '',
        verifyCode: '',
        email: '',
        password: '',
        rePassword: '',
        userName: '',
        company: ''
      },
      registerFormLabel: {
        phone: '手机号码',
        verifyCode: '短信验证码',
        email: '邮箱',
        password: '密码',
        rePassword: '确认密码',
        userName: '姓名',
        company: '公司名称'
      },
      registerRuleValidate: {
        phone: [
          {
            required: true,
            message: '手机号不能为空'
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '手机号码格式不正确',
            trigger: 'blur'
          }
        ],
        verifyCode: [
          {
            required: true,
            message: '验证码不能为空'
          }
        ],
        email: [
          {
            required: true,
            message: '邮箱不能为空',
            trigger: 'blur'
          }, {
            type: 'email',
            message: '邮箱格式错误',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '密码不能为空',
            trigger: 'blur'
          },
          {
            min: 8,
            message: '密码长度为8-32',
            trigger: 'blur'
          },
          {
            max: 32,
            message: '密码长度为8-32',
            trigger: 'blur'
          },
          {
            validator: validatePassCheck,
            trigger: 'blur'
          }
        ],
        rePassword: [
          {
            required: true,
            message: '确认密码不能为空',
            trigger: 'blur'
          },
          {
            validator: validateConfirmPassCheck,
            trigger: 'blur'
          }
        ],
        userName: [
          {
            required: true,
            message: '姓名不能为空'
          }
        ],
        company: [
          {
            required: true,
            message: '公司名称不能为空'
          }
        ]
      },
      passCharNumValid: false,
      passLengthValid: false,
      passPhoneValid: false
    };
  },
  methods: {
    async handleSubmit() {
      const isValid = await this.$refs[this.registerFormRef].validate();
      if (isValid) {
        const data = cloneDeep(this.registerForm);
        delete data.rePassword;
        const res = await this.$services.register({
          data,
          msg: '注册成功'
        });
        if (res.success) {
          setTimeout(async () => {
            await this.$router.push({ name: 'Login' });
          }, 1000);
        }
      }
    },
    handleEnter(i) {
      if (i === Object.keys(this.registerForm).length - 1) {
        this.handleSubmit();
      }
    }
  },
  watch: {
    'registerForm.password': {
      handler(newValue) {
        this.passLengthValid = !(newValue.length < 8 || newValue.length > 32);

        this.passPhoneValid = !(this.registerForm.phone && newValue.includes(this.registerForm.phone));

        const reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);

        this.passCharNumValid = reg.test(newValue);
      }
    }
  }
};
</script>

<style lang="less">
.register {
  background-color: #EDEDED;

  .ant-form .ant-form-item {
    margin-bottom: 20px;

    .ant-form-explain {
      bottom: -20px;
    }
  }

  .content {
    position: absolute;
    top: 80px;
    bottom: 50px;
    background-color: #EDEDED;
    .width-full();
    display: flex;
    justify-content: center;

    .form-wrapper {
      background: white;
      overflow: scroll;
      min-width: 1000px;
      max-width: 1000px;
      position: relative;

      .title {
        color: #333;
        margin-top: 50px;
        text-align: center;
        font-size: 20px;
      }

      .form {
        margin-top: 25px;
        margin-left: 240px;

        .ivu-form-item-content {
          display: flex;

          .ivu-form-item-error-tip {
            position: relative;
            padding-top: 0;
            line-height: 40px;
            margin-left: 10px;
          }
        }
      }
    }

    .register-btn span {
      font-size: 16px;
      font-family: PingFangSC-Semibold;
      font-weight: 500;
    }
  }

  footer {
    .width-full();
    background-color: #EDEDED;
    position: absolute;
    bottom: 0;
  }
}

.pass-valid {
  line-height: 20px;
  line-height: 20px;
}
</style>
